export default [
  {
    path: "/concatus",
    component: () =>
      import(
        /* webpackChunkName: "concat" */ "@/views/mobile/concat/index.vue"
      ),
    meta: {
      title: "联系我们",
    },
  },
];

import homeRoute from "./home";
import performanceRoute from "./performance";
import culture from "./culture";
import PeopleEntertainers from "./PeopleEntertainers";
import renyiOverview from "./renyiOverview";
import theatreMuseum from "./theatreMuseum";
import ArtsInformation from "./ArtsInformation";
import survey from "./survey";
import search from "./search";
import introduction from "./introduction";
import concat from "./concat";
import member from "./member";
export default [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/mobile/index.vue"),
    redirect: "/home",
    children: [
      ...homeRoute,
      ...performanceRoute,
      ...culture,
      ...PeopleEntertainers,
      ...renyiOverview,
      ...theatreMuseum,
      ...ArtsInformation,
      ...search,
      ...survey,
      ...introduction,
      ...concat,
      ...member,
    ],
  },
];

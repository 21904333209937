import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import MvFullPage from "mv-full-page";
import "element-ui/lib/theme-chalk/index.css"; // 全局引入
import "mv-full-page/lib-dist/mv-full-page.css";
import Vue2OrgTree from "vue2-org-tree";
import "vue2-org-tree/dist/style.css";
import CImage from "./components/pc/CImage.vue";
import Breadcrumb from "./components/pc/Breadcrumb.vue";
import Footer from "./components/pc/Footer.vue";
import myMixin from "./mixins/global.js";
import "amfe-flexible";

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(MvFullPage);
Vue.use(Vue2OrgTree);
Vue.component("CImage", CImage);
Vue.component("Breadcrumb", Breadcrumb);
Vue.component("PageFooter", Footer);
Vue.mixin(myMixin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

document.title = "北京人民艺术剧院";

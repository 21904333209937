export default [
    {
      path: "/member",
      component: () =>
        import(
          /* webpackChunkName: "concat" */ "@/views/mobile/member/index.vue"
        ),
      meta: {
        title: "会员问题",
      },
    },
  ];
  
<!--
 * @Author: zhongxq
 * @Date: 2023-10-24 13:26:01
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-29 02:16:24
 * @FilePath: \beijing-peoples-art-museum\src\components\pc\Footer.vue
 * @Description:底部footer
 *
-->
<template>
  <footer id="page-footer" :class="[
    `page-footer${$isMobile ? '-mobile' : ''}`,
    theme == 'holiday' ? 'system-bottom' : '',
  ]">
    <div class="footer-container">
      <div class="nav-bar">
        <c-image :src="require('@/assets/img/footer-logo.png')" />
        <div v-if="!$isMobile" class="nav">
          <a href="#/" target="_blank">首页</a>
          <a href="#/renyiOverView" target="_blank">人艺概览</a>
          <a href="#/renyiTheatre/capitalTheatre" target="_blank">人艺剧场</a>
          <a href="#/performance/" target="_blank">人艺演出</a>
          <a href="#/ArtsInformation/dynamic" target="_blank">人艺资讯</a>
          <a href="#/PeopleEntertainers" target="_blank">人艺人</a>
          <a href="#/culture" target="_blank">人艺文化</a>
          <a href="#/theatreMuseum" target="_blank">戏剧博物馆</a>
        </div>
      </div>
      <div v-if="!$isMobile">
        <div class="content">
          <div class="card">
            <p>服务合作</p>
            <div v-for="item in leftList.slice(0, 2)" :key="item.id" class="line" @click="toDetail(item)">
              <span>{{ item.title }}</span>
            </div>
          </div>
          <div class="card concatus " @click="goConcat">
            <p class="gds"><span style="width: 60.625vw;"> 联系我们</span> <span class="tiltight"></span></p>
          </div>
          <div class="card">
            <p>自媒体导航</p>
            <div class="qrbox-list">
              <div v-for="item in rightList" :key="item.id" class="qrbox">
                <c-image :src="item.image" />
                <div class="html-title" v-html="item.title"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="content" style="margin: 0;" @click="goMember">
          <div class="card concatmember" style="top: -2.6563vw;">
            <p class="member"><span style="width: 60.625vw;"> 会员Q&A</span> <span class="tiltight"></span></p>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="content" >
          <div class="card">
            <p>服务合作</p>
            <div v-for="item in leftList" :key="item.id" class="line" @click="toDetail(item)">
              <span>{{ item.title }}</span>
            </div>
          </div>
          <div class="card concatus " @click="goConcat">
            <p class="gds"><span style="width: 60.625vw;"> 联系我们</span> <span class="tiltight"></span></p>
          </div>
          <div @click="goMember" style="margin-top: 0.8rem;">
          <div class="card concatmember">
            <p class="member"><span style="width: 60.625vw;"> 会员Q&A</span> <span class="tiltight"></span></p>
          </div>
        </div>
  
        </div>
        <div class="content" style="margin: 0;">
          <div class="card">
            <p>自媒体导航</p>
            <div class="qrbox-list">
              <div v-for="item in rightList" :key="item.id" class="qrbox">
                <c-image :src="item.image" />
                <div class="html-title" v-html="item.title"></div>
              </div>
            </div>
          </div>
        </div>
 
      </div>

    </div>
    <div v-if="!$isMobile" class="footer-bottom" :class="theme == 'holiday' ? 'system-color-bg-color-3' : ''">
      <p>版权所有 All Rights Reserved 2002-2007, 北京人民艺术剧院</p>
      <p style="cursor: pointer" @click="toWeb()">
        <span>京公网安备 11010102002540号</span>
        <span style="margin-left: 1.0417vw">京ICP备11044998号-1</span>
        <c-image class="img1" :src="require('@/assets/img/icpbg.png')" />
        <c-image class="img2" :src="require('@/assets/img/dwbg.png')" />
      </p>
    </div>
    <div v-else class="footer-bottom" :class="theme == 'holiday' ? 'system-color-bg-color-3' : ''">
      <p>版权所有 All Rights Reserved 2002-2007, 北京人民艺术剧院</p>
      <p style="cursor: pointer" @click="toWeb()">
        <span>京公网安备 11010102002540号</span>
        <span style="margin-left: 20/75rem">京ICP备11044998号-1</span>
      </p>
      <p>
        <c-image class="img2" :src="require('@/assets/img/dwbg.png')" />
        <c-image class="img1" :src="require('@/assets/img/icpbg.png')" />
      </p>
    </div>
  </footer>
</template>

<script>
import { getArticlePage } from "@/service/culture";
import { portalHit } from "@/service/ArtsInformation";
export default {
  data() {
    return {
      leftList: [],
      rightList: [],
      isMobile: /Mobi|Android|iPhone/i.test(navigator.userAgent) || window.innerWidth <= 750,
    };
  },
  computed: {
    theme() {
      return this.$store.state.systemTheme;
    },
  },
  mounted() {
    this.getLeft();
    this.getRight();
    console.log(this.isMobile, "我是什么呢");

  },
  methods: {
    async getLeft() {
      const res = await getArticlePage({
        pageNo: 1,
        pageSize: 4,
        condition: {
          level1: "HOME",
          level2: "FWHZ",
          status: 1,
        },
      });
      this.leftList = res.data?.data || [];
    },
    async getRight() {
      const res = await getArticlePage({
        pageNo: 1,
        pageSize: 4,
        condition: {
          level1: "HOME",
          level2: "qrCode",
          status: 1,
        },
      });
      this.rightList = res.data?.data || [];
    },
    toDetail(item) {
      portalHit(item.id);
      window.open(`#/ArtsInformation/detail?id=${item.id}`, "_blank");
    },
    toWeb() {
      window.open(`https://beian.mps.gov.cn/#/query/webSearch`, "_blank");
    },
    goConcat() {
      window.open(`#/concatus`, "_blank");
    },
    goMember() {
      window.open(`#/member`, "_blank");
    },

  },
};
</script>

<style lang="less">
@import url("~@/assets/style/mixin.less");

.page-footer {
  min-width: 72.9167vw;
  height: 31.25vw;
  background-color: #21424b;
  .background-image("footer-bg.png");
  background-size: 100% 26.0417vw;
  background-repeat: no-repeat;

  .footer-container {
    margin: auto;
    width: 72.9167vw;
    height: 26.0417vw;

    .nav-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 2.6042vw 0 0.625vw;
      box-sizing: border-box;
      border-bottom: 0.0521vw solid #e8e8e8;

      .img {
        width: 18.0208vw;
        height: 3.3333vw;
        margin-left: 1.7188vw;
      }

      .nav {
        display: flex;
        align-items: center;
        margin-right: 2.8646vw;

        a {
          font-size: 0.8333vw;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #e8e8e8;
          line-height: 0.8333vw;
          text-decoration: none;
          margin-left: 2.3958vw;
        }
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      margin-top: 1.8229vw;

      .card {
        p {
          margin: 0 0 2.6042vw;
          font-size: 1.25vw;
          font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
          font-weight: bold;
          color: #f8d39f;
          line-height: 1.25vw;
          position: relative;

          &::after {
            content: "";
            width: 0.8333vw;
            height: 0.0521vw;
            background: #ffffff;
            position: absolute;
            bottom: -1.0417vw;
            left: 0;
          }
        }

        .line {
          cursor: pointer;
          width: 12.5vw;
          padding-bottom: 1.0417vw;
          position: relative;
          font-size: 0.8333vw;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #e8e8e8;
          line-height: 0.8333vw;
          border-bottom: 0.0521vw solid rgba(248, 211, 159, 0.3);

          &+.line {
            margin-top: 1.5625vw;
          }

          &::after {
            content: "";
            width: 0.8333vw;
            height: 0.0521vw;
            background-color: #f8d39f;
            position: absolute;
            right: 0;
            top: 0.2604vw;
          }

          &::before {
            content: "";
            width: 0.2604vw;
            height: 0.2604vw;
            border: 0.0521vw solid transparent;
            border-right-color: #f8d39f;
            border-bottom-color: #f8d39f;
            transform: rotate(-45deg);
            position: absolute;
            right: 0;
            top: 0.1042vw;
          }
        }

        .desc {
          font-size: 0.9375vw;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.9375vw;
          margin-top: 2.6042vw;

          &:first-child {
            margin-top: 3.125vw;
          }
        }

        .qrbox-list {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .qrbox {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-shrink: 0;

            &+.qrbox {
              margin-left: 2.6042vw;
            }

            .img {
              width: 7.2917vw;
              height: 7.2917vw;
              margin-bottom: 1.0417vw;
            }

            .html-title {
              max-width: 7.5vw;
              font-size: 0.9375vw;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #ffffff;
              line-height: 1.4583vw;
              white-space: pre-wrap;
            }
          }
        }
      }

      .concatus {
        width: 13.125vw;
        position: relative;
        cursor: pointer;

        &+.concatus {
          margin-top: 1.5625vw;
        }

        &::after {
          content: "";
          width: 0.8333vw;
          height: 0.0521vw;
          background-color: #f8d39f;
          position: absolute;
          right: 0;
          top: .6771vw;
        }

        &::before {
          content: "";
          width: 0.2604vw;
          height: 0.2604vw;
          border: 0.0521vw solid transparent;
          border-right-color: #f8d39f;
          border-bottom-color: #f8d39f;
          transform: rotate(-45deg);
          position: absolute;
          right: 0;
          top: 0.5208vw;
        }
      }

      .concatmember {
        width: 12.5vw;
        position: relative;
        cursor: pointer;

        &+.concatus {
          margin-top: 1.5625vw;
        }

        &::after {
          content: "";
          width: 0.8333vw;
          height: 0.0521vw;
          background-color: #f8d39f;
          position: absolute;
          right: 0;
          top: .6771vw;
        }

        &::before {
          content: "";
          width: 0.2604vw;
          height: 0.2604vw;
          border: 0.0521vw solid transparent;
          border-right-color: #f8d39f;
          border-bottom-color: #f8d39f;
          transform: rotate(-45deg);
          position: absolute;
          right: 0;
          top: 0.5208vw;
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    // margin: 42px 0 0;
    font-size: 0.8333vw;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 0.8333vw;
    height: 5.6771vw;

    p {
      display: flex;
      align-items: center;
      margin: 0;
    }

    .img {
      display: inline-block;
      width: 1.0417vw;
      height: 1.0417vw;
      margin-left: 1.0417vw;
    }

    .img2 {
      width: 1.3021vw;
      height: 1.3021vw;
      margin-left: 0.9375vw;
    }
  }
}

.page-footer-mobile {
  min-width: auto;
  width: 100%;
  background-color: #21424b;

  .footer-container {
    .nav-bar {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40/75rem 0;
      margin: 0 30/75rem;
      box-sizing: border-box;
      border-bottom: 1/75rem solid rgba(255, 255, 255, 0.2);

      .img {
        width: 594/75rem;
        height: 110/75rem;
      }
    }

    .gds {
      display: flex;
      align-items: center; // 垂直居中
      width: 100%;

      .tiltight {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 1.0417vw;
        position: relative;
        font-size: 26/75rem;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #e8e8e8;
        line-height: 0.8333vw;

        &+.tiltight {
          margin-top: 1.5625vw;
        }

        &::after {
          content: "";
          width: 16/75rem;
          height: 1/75rem;
          background-color: #f8d39f;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &::before {
          content: "";
          width: 5/75rem;
          height: 5/75rem;
          border: 1/75rem solid transparent;
          border-right-color: #f8d39f;
          border-bottom-color: #f8d39f;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
        }
      }

      // position: relative;
      // .tiltight {
      //   width: 0.3066667rem;
      //   height: 0.20666667rem;
      //   position: absolute;
      //   top: -6px;
      //   right: -3px;

      //   img {
      //     width: 100%;
      //     height: 100%;
      //   }
      // }

    }

    .member {
      display: flex;
      align-items: center; // 垂直居中
      width: 100%;

      .tiltight {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 1.0417vw;
        position: relative;
        font-size: 26/75rem;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #e8e8e8;
        line-height: 0.8333vw;

        &+.tiltight {
          margin-top: 1.5625vw;
        }

        &::after {
          content: "";
          width: 16/75rem;
          height: 1/75rem;
          background-color: #f8d39f;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &::before {
          content: "";
          width: 5/75rem;
          height: 5/75rem;
          border: 1/75rem solid transparent;
          border-right-color: #f8d39f;
          border-bottom-color: #f8d39f;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
        }
      }

      // position: relative;
      // .tiltight {
      //   width: 0.3066667rem;
      //   height: 0.20666667rem;
      //   position: absolute;
      //   top: -6px;
      //   right: -3px;

      //   img {
      //     width: 100%;
      //     height: 100%;
      //   }
      // }

    }

    .content {
      display: flex;
      flex-direction: column;
      margin-top: 40/75rem;
      padding: 0 30/75rem 46/75rem;

      .card {
        &+.card {
          margin-top: 60/75rem;
        }

        p {
          margin: 0 0 70/75rem;
          font-size: 34/75rem;
          font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
          font-weight: bold;
          color: #f8d39f;
          line-height: 24/75rem;
          position: relative;

          &::after {
            content: "";
            width: 28/75rem;
            height: 3/75rem;
            background: #ffffff;
            position: absolute;
            bottom: -30/75rem;
            left: 0;
          }
        }

        .line {
          cursor: pointer;
          padding-bottom: 20/75rem;
          position: relative;
          font-size: 26/75rem;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #e8e8e8;
          line-height: 26/75rem;
          border-bottom: 1/75rem solid rgba(248, 211, 159, 0.3);
          margin-top: 40/75rem;

          &::after {
            content: "";
            width: 16/75rem;
            height: 1/75rem;
            background-color: #f8d39f;
            position: absolute;
            right: 0;
            top: 5/75rem;
          }

          &::before {
            content: "";
            width: 5/75rem;
            height: 5/75rem;
            border: 1/75rem solid transparent;
            border-right-color: #f8d39f;
            border-bottom-color: #f8d39f;
            transform: rotate(-45deg);
            position: absolute;
            right: 0;
            top: 2/75rem;
          }
        }

        .desc {
          font-size: 26/75rem;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          line-height: 26/75rem;
          margin-top: 30/75rem;

          &:first-child {
            margin-top: 40/75rem;
          }
        }

        .qrbox-list {
          display: flex;
          align-items: center;

          .qrbox {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-shrink: 0;

            &+.qrbox {
              margin-left: 22/75rem;
            }

            .img {
              width: 156/75rem;
              height: 156/75rem;
              margin-bottom: 10/75rem;
            }

            .html-title {
              max-width: 156/75rem;
              font-size: 18/75rem;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #ffffff;
              line-height: 28/75rem;
              white-space: pre-wrap;
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 183/75rem;
    font-size: 18/75rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 18/75rem;
    border-top: 1/75rem solid rgba(255, 255, 255, 0.2);
    // margin: 0 30/75rem;
    box-sizing: border-box;

    p {
      display: flex;
      align-items: center;
      margin: 0;
    }

    .img {
      display: inline-block;
      width: 20/75rem;
      height: 20/75rem;
      margin-left: 20/75rem;
    }

    .img2 {
      width: 25/75rem;
      height: 25/75rem;
      margin-left: 18/75rem;
    }
  }
}
</style>
